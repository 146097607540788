<template>
  <div>
      <div class="d-flex justify-start items lm-bookmark-item">
        <template>
          <div class="pr-sm-3 mt-2 mt-sm-0 item">
            <div class="position-relative lm__tile--link">
              <span class="delete"
                ><v-icon
                  @click.native="deleteMessage"
                  color="black"
                  >mdi-close</v-icon
                ></span
              >
              <a :href="`${$baseUrl}${$_getlocaleParam()?'/':''}${$_getlocaleParam()?$_getlocaleParam():''}/model/${bookmark.unique_id}`">
                <div>
                  <picture>
                    <img
                      data-async-image="true"
                      :src="bookmark.model.thumbnail"
                      class="model__image"
                      decoding="async"
                  /></picture>
                </div>
                <!--  -->
                <div class="model__title text-uppercase mt-2">
                  {{ $_changeModelProperty(bookmark, "first_name") }}
                </div>
                <div class="model__sub-title">
                  {{ bookmark.country.name }}
                  {{ bookmark.model.height }} /
                  {{ bookmark.model.weight }} /
                  {{ bookmark.model.bust }} /
                  {{ bookmark.model.waist }} /
                  {{ bookmark.model.hips }}
                </div>
              </a>
            </div>
          </div>
        </template>
      </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import toast from "@/utils/toast";
export default {
  props: {
    bookmark: {
      type: Object,
    },
  },
  methods: {
    ...mapActions("bookmark", ["deleteBookmark"]),
    async deleteMessage() {
      toast.question(this.$_trans("toast.del_bookmark_item_msg"), this.$_trans("toast.del_bookmark_item_title"), async () => {
        await this.deleteBookmark(this.bookmark.id);
      });
    },
  },
};
</script>
<style scoped>
.delete {
  background-color: var(--color-bg-white);
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0.9;
  z-index: 0;
  cursor: pointer;
}

.lm-bookmark-item {
    flex: 1;
    max-width: 230px;
}

.lm__tile--link a {
  text-decoration: none;
  display: block;
  color: initial;
}
</style>
