<template>
  <div class="wa__container">
    <header class="pt-3">
      <div class="pt-3 pl-1">
        <v-row>
          <v-col cols="12">
            <site-header
              backRouteName="dashboard"
              :backText="$_t('backText')"
              :title="$_t('title')"
              :sub-title="$_t('subTitle')"
            ></site-header>
          </v-col>
        </v-row>
      </div>
    </header>
    <main>
      <div>
        <v-row class="justify-content-center pb-4 wa__hiring__requests">
          <v-col cols="12"><BookmarkItems /></v-col>
        </v-row>
      </div>
    </main>
    <footer>
      <div class="wa__home--footer">
        <site-footer :text="$_t('footerText')"></site-footer>
      </div>
    </footer>
    <div class="text-center">
    </div>
  </div>
</template>
<script>
import BookmarkItems from "../components/Bookmark/Items.vue";
export default {
  components: {
    BookmarkItems,
  },
};
</script>