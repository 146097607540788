<template>
<list :title="$_t('models')" class="wa__large">
    <hr class="mt-0" />
    <data-iterator :items="getBookmarks" @pagination="changePagination" :pagination="getPaginationBookmark" :loading="getBookmarkLoading" class="row mt-0 pa-1 lm-bookmark-items">
        <template v-slot:loading>
            <loading />
        </template>
        <template #no-items>
            <no-items />
        </template>
        <template #no-results>
            <no-items />
        </template>

        <template v-slot:items="{item}">
            <bookmark-item :key="item.id" :bookmark="item" />
        </template> 
    </data-iterator>
</list>
</template>

<script>
import BookmarkItem from "./Item";
import {
    mapActions,
    mapGetters,
    mapMutations
} from "vuex";

export default {
    components: {
        BookmarkItem,
    },
    methods: {
        ...mapActions("bookmark", ["loadBookmarks"]),
        ...mapMutations("bookmark", ["SET_PAGINATION"]),
        changePagination(pagination) {
            if (this.getPaginationBookmark.page !== pagination.page) {
                this.SET_PAGINATION(pagination);
                this.loadBookmarks();
            }
        },
    },
    computed:{
        ...mapGetters('bookmark', ['getBookmarks','getBookmarkLoading','getPaginationBookmark']),
    },
    created() {
        this.loadBookmarks();
    },
};
</script>

<style scoped>
.lm-bookmark-items {
    display: flex;
    flex-wrap: wrap;
}
</style>
